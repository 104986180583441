/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */
// @ts-nocheck

import * as Types from '../../../lib/graphql.document';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { CommentFragmentFragmentDoc } from '../../../gql/CommentFragment.document';
import { NotificationPostFragmentDoc } from '../NotificationsMenu/gql/NotificationPost.document';
export type NotificationSubscriptionVariables = Types.Exact<{
  userId: Types.Scalars['ID'];
}>;


export type NotificationSubscription = { __typename?: 'Subscription', notificationReceived: { __typename?: 'NotificationEdge', cursor: string, node: { __typename?: 'CommentAddedNotification', id: string, isViewed: boolean, createdAt: number | string, comment: { __typename?: 'Comment', id: string, isPessimized: boolean, createdAt: number | string, body: string, parent?: { __typename?: 'Comment', id: string } | null, post: { __typename?: 'MediaPost', slug: string, searchParams: string, title: string, cover?: { __typename?: 'Cover', originalUrl: string, alt?: string | null, cropSettings?: unknown | null, source?: string | null } | null }, deleted?: { __typename?: 'CommentDeleted', deletedAt: number | string } | null, author: { __typename?: 'User', id: string, alias: string, nickname?: string | null, description?: string | null, moderatorComment?: string | null, banned: boolean, avatar?: { __typename?: 'Avatar', originalUrl: string, cropSettings?: unknown | null } | null }, reactions: { __typename?: 'Reactions', likesCount: number, dislikesCount: number, myReaction: Types.Reaction }, replyTo?: { __typename?: 'Comment', id: string, author: { __typename?: 'User', id: string, alias: string, nickname?: string | null, description?: string | null, moderatorComment?: string | null, banned: boolean, avatar?: { __typename?: 'Avatar', originalUrl: string, cropSettings?: unknown | null } | null }, parent?: { __typename?: 'Comment', id: string } | null } | null } } | { __typename?: 'ModerationRejectedNotification', id: string, isViewed: boolean, createdAt: number | string, mediaPost: { __typename?: 'MediaPost', id: string, title: string, slug: string, searchParams: string } } } };


export const NotificationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"Notification"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"notificationReceived"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"userId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"cursor"}},{"kind":"Field","name":{"kind":"Name","value":"node"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"isViewed"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"CommentAddedNotification"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"comment"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CommentFragment"}}]}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ModerationRejectedNotification"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"mediaPost"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"NotificationPost"}}]}}]}}]}}]}}]}},...CommentFragmentFragmentDoc.definitions,...NotificationPostFragmentDoc.definitions]} as unknown as DocumentNode<NotificationSubscription, NotificationSubscriptionVariables>;