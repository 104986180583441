/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */
// @ts-nocheck

import * as Types from '../../../../lib/graphql.document';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { CommentFragmentFragmentDoc } from '../../../../gql/CommentFragment.document';
export type ViewAllNotificationsMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type ViewAllNotificationsMutation = { __typename?: 'Mutation', viewAllNotifications: Array<{ __typename?: 'CommentAddedNotification', id: string, isViewed: boolean, comment: { __typename?: 'Comment', id: string, isPessimized: boolean, createdAt: number | string, body: string, parent?: { __typename?: 'Comment', id: string } | null, post: { __typename?: 'MediaPost', slug: string, searchParams: string, title: string, cover?: { __typename?: 'Cover', originalUrl: string, alt?: string | null, cropSettings?: unknown | null, source?: string | null } | null }, deleted?: { __typename?: 'CommentDeleted', deletedAt: number | string } | null, author: { __typename?: 'User', id: string, alias: string, nickname?: string | null, description?: string | null, moderatorComment?: string | null, banned: boolean, avatar?: { __typename?: 'Avatar', originalUrl: string, cropSettings?: unknown | null } | null }, reactions: { __typename?: 'Reactions', likesCount: number, dislikesCount: number, myReaction: Types.Reaction }, replyTo?: { __typename?: 'Comment', id: string, author: { __typename?: 'User', id: string, alias: string, nickname?: string | null, description?: string | null, moderatorComment?: string | null, banned: boolean, avatar?: { __typename?: 'Avatar', originalUrl: string, cropSettings?: unknown | null } | null }, parent?: { __typename?: 'Comment', id: string } | null } | null } } | { __typename?: 'ModerationRejectedNotification', id: string, isViewed: boolean }> };


export const ViewAllNotificationsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ViewAllNotifications"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"viewAllNotifications"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"isViewed"}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"CommentAddedNotification"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"comment"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CommentFragment"}}]}}]}}]}}]}},...CommentFragmentFragmentDoc.definitions]} as unknown as DocumentNode<ViewAllNotificationsMutation, ViewAllNotificationsMutationVariables>;