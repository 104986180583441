import { Page } from '../../components/Page';

interface RenderErrorPageProps {
  error: unknown;
}

const RenderErrorPage = ({ error }: RenderErrorPageProps): JSX.Element => {
  const errorMessage = getErrorMessage(error);

  return (
    <Page
      className="mx-5 flex flex-col items-center justify-center whitespace-pre-line"
      documentTitle="Внутренняя ошибка"
      statusCode={500}
      hideAdvertisement
    >
      <p>Произошла внутренняя ошибка. Попробуйте обновить страницу.</p>
      {errorMessage && <p>{errorMessage}</p>}
    </Page>
  );
};

const getErrorMessage = (error: unknown): string | undefined => {
  if (error instanceof Error) {
    return error.message;
  }

  return undefined;
};

export { RenderErrorPage };
export type { RenderErrorPageProps };
